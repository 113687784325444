import envConfigDev from "./config.dev.json";
import envConfigProd from "./config.prod.json";
import envConfigUat from "./config.uat.json";
import rootConfig from "./config.json";

interface Config {
    accessTokenName: string;
    authenticateApiUrl: string;
    authorizationCodeName: string;
    authWebUrl: string;
    cloudApiBaseUrl: string;
    connectWebUrl: string;
    databrokerApiUrl: string;
    deathEngineApiUrl: string;
    emailApiUrl: string;
    fasanoBucketName: string;
    fasanoClientWebUrl: string;
    fasanoWebUrl: string;
    fileApiUrl: string;
    fasanoHelpEmail: string;
    idleTimeout: number;
    leOrderApiUrl: string;
    fasanoNoReplyEmail: string;
    leCalculationApiUrl: string;
    servicingReportApiUrl: string;
    servicingWebUrl: string;
    siteMapApiUrl: string;
    thumbnailBucketName: string;
    twentyFirstLEClientID: string;
    twentyFirstLEWebUrl: string;
    twentyFirstLEHelpEmail: string;
    underwritingApiBaseUrl: string;
    uwDateToClientApiUrl: string;
    webAppBucketName: string;
}

const env: "dev" | "uat" | "prod" = import.meta.env.VITE_APP_ENVIRONMENT || "dev";
const envMap = {
    dev: envConfigDev,
    uat: envConfigUat,
    prod: envConfigProd
};

const envConfig: Config = envMap[env] as Config;

const configSettings: Config = {
    ...rootConfig,
    ...envConfig
};

export default configSettings;
